import React, {useContext} from "react";
import { createDrawerNavigator, DrawerItem, DrawerItemList, DrawerContentScrollView} from "@react-navigation/drawer"
import Welcome from "../screens/Welcome";
import addTools from "../screens/addTools";
import editTools from "../screens/editTools";
import Signup from "../screens/Signup"
import Users from "../screens/Users";

import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CredentialsContext } from '../components/CredentialsContext';
import { View , Text} from "react-native";
import { PageTitle, SubTitle, Avatar } from "../components/styles";
import { createStackNavigator } from "@react-navigation/stack";


const Drawwer = createDrawerNavigator();
const Stackk = createStackNavigator();

const LogedIn = () => {

  const { storedCredentials, setStoredCredentials } = useContext(CredentialsContext);
  const { name, vorname, photoUrl, access_token} = storedCredentials;

  const clearLogin = () => {
    AsyncStorage.removeItem('flowerCribCredentials')
      .then(() => {
        setStoredCredentials("");
      })
      .catch((error) => console.log(error));
  };


  function CustomDrawerContent(props) {
    return (
      <>
      <DrawerContentScrollView {...props}>
      <View>
          <Avatar resizeMode="cover" source={require('./../assets/Logo_Zimmermann.png')}/>
          <Text style={{textAlign:"center", fontSize:15 }}>Benutzer:</Text>
          <Text style={{textAlign:"center", fontSize:20}}>{vorname} {name}</Text>
          
          </View>
        
        <DrawerItemList {...props} />
        
        <DrawerItem
          label="Menü schliesen"
          onPress={() => props.navigation.closeDrawer()} />
        <DrawerItem
          label="Logout"
          onPress={clearLogin} />
          
      </DrawerContentScrollView>
      
      </>
    );

    
  }
  

    return(
        <>
        <StatusBar style="light"/>
        {storedCredentials.account_typ == 'admin' ? (<Drawwer.Navigator screenOptions={{headerStatusBarHeight:20}} drawerContent={(props) => <CustomDrawerContent {...props} />}>
            <Drawwer.Screen name="Werkzeuge" component={Welcome}/>
            <Drawwer.Screen name="Werkzeug hinzufügen" component={addTools}/>
            <Drawwer.Screen name="Benutzer" component={Users}/>
           
            
            
              </Drawwer.Navigator>):
                   
                   (<Drawwer.Navigator drawerContent={(props) => <CustomDrawerContent {...props} />}>
            <Drawwer.Screen name="Werkzeuge" component={Welcome}/>
             
                   </Drawwer.Navigator>
                   )}

        
    </>)
};

export default LogedIn; 