import React from 'react';

//colors
import { Colors } from './../components/styles';
const { darkLight, brand, primary, tertiary, secondary } = Colors;

// React Navigation
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';

 

import { CredentialsContext } from './../components/CredentialsContext';
import Signup from '../screens/Signup';
import LogedIn from './LogedIn'
import Login from '../screens/Login';
import editTools from '../screens/editTools';
import changeStorage from '../screens/changeStorrage';
import WebPrint from '../screens/WebPrint'
import WebPrintNewObject from '../screens/WebPrintNewObject'
import EditUsers from '../screens/editUsers';
import SignupCompany from '../screens/SignupCompany';

const Stack = createStackNavigator();

const RootStack = () => {
  return (
    <CredentialsContext.Consumer>
      {({ storedCredentials }) => (
        <NavigationContainer style={{ backgroundColor: 'red' }}>
          <Stack.Navigator
             screenOptions={{
              headerStyle: {
                backgroundColor: 'transparent',
              
              },
              headerTintColor: tertiary,
              headerTransparent: true,
              headerTitle: '',
             
              headerLeftContainerStyle: {
                paddingLeft: 20,
                
              },
            }}
          >
            {storedCredentials ? (
              <><Stack.Screen name="Welcome"component={LogedIn} />
                <Stack.Screen name="Lagerort speichern" component={changeStorage} />
                <Stack.Screen name="Werkzeugdetails" component={editTools} />
                <Stack.Screen name="Webprint" component={WebPrint} options={{headerLeft: null}}/>
                <Stack.Screen name="Benutzer hinzufügen" component={Signup}/>
                <Stack.Screen name="Benutzerdetails" component={EditUsers}/>
                <Stack.Screen name="WebprintNewObject" component={WebPrintNewObject} options={{headerLeft: null}}/>

                </>            
              
            ) : (
              <>
                <Stack.Screen name="Login" component={Login} />
                <Stack.Screen name="Signup"  component={SignupCompany} />
                
                

               
              </>
            )}
          </Stack.Navigator>
        </NavigationContainer>
      )}
    </CredentialsContext.Consumer>
  );
}; 

export default RootStack;
