import React, { useState, useContext, useEffect, useRef} from 'react';
import { webUrl } from '../components/connection';
import { StatusBar } from 'expo-status-bar';
import { Octicons, Ionicons, FontAwesome5 } from '@expo/vector-icons';
import { Formik } from 'formik';
import { View, TouchableOpacity, ActivityIndicator, Image, Platform, TextInput, Text } from 'react-native';
import KeyboardAvoidingWrapper from './../components/KeyboardAvoidingWrapper';
import axios from 'axios';
import { CredentialsContext } from './../components/CredentialsContext';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import * as WebBrowser from 'expo-web-browser';
import { useIsFocused } from '@react-navigation/core';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  StyledContainer,
  PageTitle,
  StyledInputLabel,
  StyledFormArea,
  StyledButton,
  StyledTextInput,
  LeftIcon,
  RightIcon,
  InnerContainer,
  ButtonText,
  MsgBox,
  Line,
  ExtraView,
  ExtraText,
  TextLink,
  TextLinkContent,
  SubTitle,
  Colors,
} from './../components/styles';
//import { TextInput } from 'react-native-gesture-handler';


//colors
const { darkLight, brand, primary } = Colors;


const AddTools = ({ navigation }) => {
  
 
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState();
  const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
  const { name, vorname, photoUrl, access_token, company} = storedCredentials;
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [serverResponseImage, setServerResponseImage] = useState();
  const [serverResponseFile, setServerResponseFile] = useState();
  const didMountImage = useRef(false);
  const isFocused = useIsFocused();
  const didMountFile = useRef(false);
  const[saved, setSaved] = useState(false);


  useEffect(() => {
    if(isFocused == true){
      
      
    } else{
      setSaved(false);
      
      //alert("lost Focus welcome")
    }
  }
  , [isFocused])

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  useEffect(() =>{
    if(didMountImage.current){uploadImage();
    } else {
      didMountImage.current = true

    } },[image])

    useEffect(() =>{
      if(didMountFile.current){uploadFile();
      } else {
        didMountFile.current = true
  
      } },[file])
  
  
  
  // Form handling
  const handleSignup = (credentials, setSubmitting, values) => {
    
    //const dataUpload=JSON.stringify({"name":"f","lagerort":"f","ursprungsort":"f","imageId":"1","fileId":"2"});
  //if(serverResponseImage !="emty") {credentials.image = serverResponseImage}else{alert("noimage")};    
  credentials.image = serverResponseImage;  
  credentials.file1 = serverResponseFile;
    credentials.file2 = "";
    credentials.file3 = "";
   // console.log(credentials);
  //  console.log(serverResponse);
    handleMessage(null);
    const url = 'https://www.ztool.ch/werkzeug/werkzeug/create.php?company='+company+'';
    //console.log("ceredentials",credentials,"comany"+company)
    axios
    
      .post(url, credentials,  {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
      .then((response) => {
        
        const result = response.data;
        const { status, message, data } = result;
        if(status == 'success'){setSaved(true);}else{setSaved(false)};
        //console.log(status);
        try { AsyncStorage.setItem("QrCode" , result.document);
        //console.log('saved', result.document);
        //navigation.navigate('Webprint');
      } catch (err) {
        alert(err);
      }
      
        
        setImage(false)
        setFile(false);
        

        if (status !== 'SUCCESS') {
          handleMessage(message, status);
        } 
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error)
        handleMessage('An error occurred. Check your network and try again');
        //console.log(error.toJSON());
      });
  };

  

  const handleMessage = (message, type = '') => {
    setMessage(message);
    setMessageType(type);
  };

  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: '*/*',
      base64: true
    }); 
    if (!result.cancelled) {
      if(Platform.OS !== 'web'){
        const base64 = await FileSystem.readAsStringAsync(result.uri,{ encoding: FileSystem.EncodingType.Base64 })
        //console.log(result);     
        setFile({
        
        "nameBilder": result.name,
        "mimeType": result.mimeType,
        "size": result.size,
        "data": base64,
      });
        
    } else {
      //console.log('Platform Web', result)
      const base64 = result.uri.split(',').pop();
      //console.log('base64', base64);

     setFile({
        
        "nameBilder": result.name,
        "mimeType": result.mimeType,
        "size": result.size,
        "data": base64,
      });
      
    }
  }
  };

  const uploadFile = async () => {
      
      
    var axios = require('axios');
    var data = JSON.stringify(file);
    //console.log(data);
    var config = {
      method: 'post',
      url: webUrl+'files/uploadfile.php',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${access_token}`
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data.document));
      setServerResponseFile(response.data.document);
    })
    .catch(function (error) {
      console.log(error);
    });
    
}





  const pickImage = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: 'image/*',
      base64: true
    }); 
    if (!result.cancelled) {
      if(Platform.OS !== 'web'){
        const base64 = await FileSystem.readAsStringAsync(result.uri,{ encoding: FileSystem.EncodingType.Base64 })
        //console.log(result);     
        setImage({
        
        "nameBilder": result.name,
        "mimeType": result.mimeType,
        "size": result.size,
        "data": base64,
      });
        
    } else {
      //console.log('Platform Web', result)
      const base64 = result.uri.split(',').pop();
      //console.log('base64', base64);

     setImage({
        
        "nameBilder": result.name,
        "mimeType": result.mimeType,
        "size": result.size,
        "data": base64,
      });
      
    }
  }
  };


  const uploadImage = async () => {
      
      
    var axios = require('axios');
    var data = JSON.stringify(image);
    //console.log(data);
    var config = {
      method: 'post',
      url: webUrl+'files/uploadimage.php',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${access_token}`
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data.document));
      setServerResponseImage(response.data.document);
    })
    .catch(function (error) {
      console.log(error);
    });
    
}
 const openFile = async () =>{
   await WebBrowser.openBrowserAsync(webUrl+'files/upload/'+file.nameBilder);
   
 }

 const print = async () => {
      
     
      navigation.navigate("WebprintNewObject");
       }


  return (
    <KeyboardAvoidingWrapper>
      <StyledContainer>
        <StatusBar style="dark" />
        <InnerContainer>
          <PageTitle>Werkzeug</PageTitle>
          <SubTitle>hinzufügen</SubTitle>         
          <Formik
            initialValues={{ id: '', name: '', lagerort: '', ursprungsort: '', info: '' }}
            onSubmit={(values, { setSubmitting }) => {
              
              values = { ...values};
              if (
               // values.id == '' ||
                values.name == '' ||
                values.lagerort == '' ||
                values.ursprungsort == ''||
                values.image == ''
              ) {
                handleMessage('Bitte alle Felder ausfüllen');
                setSubmitting(false);
              }  else {
                handleSignup(values, setSubmitting);
                
              }
            }}
          >
            {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
              <StyledFormArea>

                <MyTextInput
                  label="ID"
                  placeholder="ID wirt automatisch generiert"
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('id')}
                  onBlur={handleBlur('id')}
                  value={values.id}
                  icon="tools"
                  editable={false}
                />

                <MyTextInput
                  label="Bezeichnung"
                  placeholder="Akkuschrauber"
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('name')}
                  onBlur={handleBlur('name')}
                  value={values.name}
                  icon="tools"
                />
                <MyTextInput
                  label="Lagerort"
                  placeholder="123"
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('lagerort')}
                  onBlur={handleBlur('lagerort')}
                  value={values.lagerort}
                  icon="chevron-right"
                />
                <MyTextInput
                  label="Ursprungsort"
                  placeholder="El-Halle"
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('ursprungsort')}
                  onBlur={handleBlur('ursprungsort')}
                  value={values.ursprungsort}
                  icon="home"
                  
                />
                <MyTextInput
                  label="Info"
                  placeholder="...."
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('info')}
                  onBlur={handleBlur('info')}
                  value={values.info}
                  icon="home"
                  
                />
                <StyledButton onPress={pickImage}>
                  <ButtonText>Foto hinzufügen</ButtonText>
                </StyledButton>
               {image && (
                <Image style={{width: 100, height: 50}} source={{uri: 'data:application/pdf;base64,'+image.data}}/>
                 )}
                <MsgBox type={messageType}>{message}</MsgBox>
                <StyledButton onPress={pickFile}>
                  <ButtonText>Datei hinzufügen</ButtonText>
                </StyledButton>
                {file &&(
                <Text onPress={openFile}>{file.nameBilder}</Text>
                )}
                <Line />
                {!isSubmitting && (
                  <StyledButton onPress={handleSubmit}>
                    <ButtonText>Hinzufügen</ButtonText>
                  </StyledButton>
                )}
                {isSubmitting && (
                  <StyledButton disabled={true}>
                    <ActivityIndicator size="large" color={primary} />
                  </StyledButton>
                )}
                 
                 {Platform.OS =='web'&& saved == true&& ( <StyledButton onPress={print}>
                  <ButtonText>Etikett drucken</ButtonText>
                </StyledButton>)}
                
              </StyledFormArea>
            )}
          </Formik>
        </InnerContainer>
      </StyledContainer>
    </KeyboardAvoidingWrapper>
  );
};

const MyTextInput = ({ label, icon, isPassword, hidePassword, setHidePassword, isDate, showDatePicker, ...props }) => {
  return (
    <View>
      <LeftIcon>
        <Octicons name={icon} size={30} color={brand} />
      </LeftIcon>
      <StyledInputLabel>{label}</StyledInputLabel>

      
      <StyledTextInput {...props} />

      {isPassword && (
        <RightIcon
          onPress={() => {
            setHidePassword(!hidePassword);
          }}
        >
          <Ionicons name={hidePassword ? 'md-eye-off' : 'md-eye'} size={30} color={darkLight} />
        </RightIcon>
      )}
    </View>
  );
};

export default AddTools;
