

import React, { useContext, useEffect, useRef, useState } from 'react';
import { webUrl } from '../components/connection';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Text, TouchableOpacity, Button, Platform, Image, Keyboard } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { Camera } from 'expo-camera';
import { useIsFocused } from '@react-navigation/core';
import { useFocusEffect} from '@react-navigation/native';
import { BarCodeScanner } from 'expo-barcode-scanner';






import {
  Avatar,
  WelcomeImage,
  PageTitle,
  SubTitle,
  StyledFormArea,
  StyledButton,
  InnerContainer,
  WelcomeContainer,
  ButtonText,
  Line,
  styles
} from './../components/styles';

// Async storage
import AsyncStorage from '@react-native-async-storage/async-storage';

// credentials context
import { CredentialsContext } from './../components/CredentialsContext';
import { TextInput } from 'react-native-gesture-handler';

const Users = ({navigation}) => {
  // credentials context
  const { storedCredentials, setStoredCredentials } = useContext(CredentialsContext);

  const { name, vorname, photoUrl, access_token, company} = storedCredentials;
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState();
  const isFocused = useIsFocused();
  const firstLoad = useRef(true)
  ////console.log(access_token)
/*useFocusEffect(
  React.useCallback(() => {
     getWerkzeug();
   // alert('Screen was focused');
    // Do something when the screen is focused
    return () => {
      
      //alert('Screen was unfocused');
      // Do something when the screen is unfocused
      // Useful for cleanup functions
    };
  }, [])
);*/


useEffect(() => {
  if(isFocused == true){
    
    getWerkzeug();
  } else{
    setItems([]);
    setCurrentPage(1);
    
    //alert("lost Focus welcome")
  }
}
, [isFocused])

useEffect(() => {
 
  if(isFocused == true){ 
     getWerkzeug();
  }
}, [currentPage])

useEffect(() => {
  setItems([]);     
  setCurrentPage(1);
  getWerkzeug();
  
}, [searchText])

function openUser(type, data) {
  try { AsyncStorage.setItem("BenutzerID" , data);
  //console.log('saved', data);
  navigation.navigate('Benutzerdetails');
} catch (err) {
  alert(err);
}

  
  
  ////console.log(type + data)
}

const [hasPermission, setHasPermission] = useState(null);
const [scanned, setScanned] = useState(false);







function getWerkzeug(text) {
   
  var axios = require('axios');
  var data = JSON.stringify([
    {
      "columnName": "ID",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
    {
      "columnName": "name",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
    {
      "columnName": "vorname",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
    {
      "columnName": "email",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
    {
      "columnName": "account_typ",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
  ]);
  
  const pagesize = 30;
    
    var config = {
      method: 'post',
      url: webUrl+'benutzer/search_by_column.php?orAnd=OR&pageno='+currentPage+'&pagesize='+pagesize+'&company='+company+'',
     //url: webUrl+'werkzeug/search_by_column.php?orAnd=OR&pageno=1&pagesize=5',
      headers: { 
        
        'Authorization': `Bearer ${access_token}`,
        'Cache-Control' : 'no-cache'
      },
      data : data
    };
    
    axios(config)
    .then(function (res) {    
      //console.log(res.data);
     setTotalPages(Math.ceil(res.data.document.total_count/pagesize));
     //console.log("Totalpages",totalPages, "currentPage", currentPage);
     
     
      
    // setItems(res.data.document.records);
    
       const filtered = res.data.document.records;
       //console.log(filtered)
       

      if(currentPage == 1){
      setItems(filtered)
      //console.log(items);
         
    }
    else{
      setItems([...items,...filtered])
     // console.log(items);
         
    }
    
    })
    .catch(function (error) {
     
      console.log(error);
    });
    
          
      }

    const loadMoreItem =() => {
      //console.log("load more totalPages",totalPages ,"current page", currentPage);
     
      
      if(currentPage< totalPages) {
      setCurrentPage(currentPage+1);
      //console.log("loadMore");
    }
    } 

const addUser =() => {navigation.navigate("Benutzer hinzufügen")}

const Footer = () =>  {
  
  if(Platform.OS == "web"){ 
   return( <View>{currentPage < totalPages &&(<StyledButton onPress={loadMoreItem}>
    <ButtonText>Load more</ButtonText>
  </StyledButton>)}
  </View>)}
  else{
    return(<View/>)
  }
} ;


  return (
    
    
      <><FlatGrid
      ListFooterComponent={<Footer/>}
      ListHeaderComponent={<View>
        <StatusBar style="light" />
        <StyledButton onPress={addUser}>
        <ButtonText>Benutzer hinzufügen</ButtonText>
        </StyledButton>
        <TextInput placeholder={"Nach Benutzer suchen"} style={styles.input} onChangeText={(text) => { setSearchText(text); } } />
      </View>}
      keyExtractor={(item) => item.ID}
      itemDimension={130}
      data={items}
      style={styles.gridView}
      onEndReachedThreshold={0.5}
      //onEndReached={//console.log("End")}
      onEndReached={() => {
        loadMoreItem();
      } }
      // staticDimension={300}
      // fixed
      spacing={10}
      renderItem={({ item }) => (
        <TouchableOpacity onPress={() => openUser({}, item.ID)}>
          <View style={[styles.itemContainer, { backgroundColor: '#1abc9c' }]}>
          
            <Text style={styles.itemName}>{item.ID}</Text>
            <Text style={styles.itemName}>{item.name}</Text>
            <Text style={styles.itemCode}>{item.vorname}</Text>
           
          </View>
        </TouchableOpacity>
      )} />
      </>
    
  );
};

export default Users;

