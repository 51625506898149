import React, {useState, useContext} from "react";
import { webUrl } from '../components/connection';
import {View, Text, ActivityIndicator} from 'react-native';
import { Formik } from "formik";
import { Octicons, Ionicons } from "@expo/vector-icons";
import axios from "axios";
import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {  StyledContainer,
    PageLogo,
    PageTitle,
    SubTitle,
    StyledInputLabel,
    StyledFormArea,
    StyledButton,
    StyledTextInput,
    LeftIcon,
    RightIcon,
    InnerContainer,
    ButtonText,
    MsgBox,
    Line,
    ExtraView,
    ExtraText,
    TextLink,
    TextLinkContent,
    Colors, } from "../components/styles";
    import KeyboardAvoidingWrapper from "../components/KeyboardAvoidingWrapper";
    import { CredentialsContext } from "../components/CredentialsContext";
  

    //colors
const { darkLight, brand, primary } = Colors;

const Login = ({navigation}) => {
    /*return (
        <View style={{flex:1, alignItems:'center', justifyContent:'center'}}>
            <Text>Hello, This is Login Screen</Text>
        </View>
    )*/
    const [hidePassword, setHidePassword] = useState(true);
    const [message, setMessage] = useState();
    const [messageType, setMessageType] = useState();
    
    // credentials context
    const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
  
    const handleLogin = (credentials, setSubmitting) => {
      handleMessage(null);
      const url = webUrl+'token/generate.php'; /*'https://whispering-headland-00232.herokuapp.com/user/signin';*/
      axios
        .post(url, credentials)
        .then((response) => {
          const result = response.data;
          const { status, message, data, user } = result;
          //console.log(response);
          
         
          
  
          if (status !== 'SUCCESS') {
            handleMessage(message, status);
          } else {
            persistLogin({ ...data }, message, status);
            //console.log( {...data}) ;
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          handleMessage('An error occurred. Benutzer und Passwort prüfen');
          console.log(error.toJSON());
        });
    };
  
    const handleMessage = (message, type = '') => {
      setMessage(message);
      setMessageType(type);
    };
  
  
  
    // Persisting login
    const persistLogin = (credentials, message, status) => {
      AsyncStorage.setItem('flowerCribCredentials', JSON.stringify(credentials))
        .then(() => {
          handleMessage(message, status);
          setStoredCredentials(credentials);
        })
        .catch((error) => {
          handleMessage('Persisting login failed');
          console.log(error);
        });
    };
  
    return (
      <KeyboardAvoidingWrapper>
        <StyledContainer>
          <StatusBar style="dark" />
          <InnerContainer>
            <PageLogo resizeMode="cover" source={require('./../assets/Logo_Zimmermann.png')} />
            <PageTitle>Werkzeugverwaltung</PageTitle>
            <SubTitle>Bitte einloggen</SubTitle>
  
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={(values, { setSubmitting }) => {
                if (values.email == '' || values.password == '') {
                  handleMessage('Please fill in all fields');
                  setSubmitting(false);
                } else {
                  handleLogin(values, setSubmitting);
                }
              }}
            >
              {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
                <StyledFormArea>
                  <MyTextInput
                    label="Email Address"
                    placeholder="andyj@gmail.com"
                    placeholderTextColor={darkLight}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    keyboardType="email-address"
                    icon="mail"
                  />
                  <MyTextInput
                    label="Password"
                    placeholder="* * * * * * * *"
                    placeholderTextColor={darkLight}
                    onChangeText={handleChange('password')}
                    onBlur={handleBlur('password')}
                    value={values.password}
                    secureTextEntry={hidePassword}
                    icon="lock"
                    isPassword={true}
                    hidePassword={hidePassword}
                    setHidePassword={setHidePassword}
                  />
                  <MsgBox type={messageType}>{message}</MsgBox>
  
                  {!isSubmitting && (
                    <StyledButton onPress={handleSubmit}>
                      <ButtonText>Login</ButtonText>
                    </StyledButton>
                  )}
                  {isSubmitting && (
                    <StyledButton disabled={true}>
                      <ActivityIndicator size="large" color={primary} />
                    </StyledButton>
                  )}
                    <Line/>
                    <ExtraView>
                    <ExtraText>Hast du noch keinen Account? </ExtraText>
                    <TextLink onPress={() => navigation.navigate('Signup')}>
                      <TextLinkContent>Signup</TextLinkContent>
                    </TextLink>
                  </ExtraView>
                </StyledFormArea>
              )}
            </Formik>
          </InnerContainer>
        </StyledContainer>
      </KeyboardAvoidingWrapper>
    );
  };
  
  const MyTextInput = ({ label, icon, isPassword, hidePassword, setHidePassword, ...props }) => {
    return (
      <View>
        <LeftIcon>
          <Octicons name={icon} size={30} color={brand} />
        </LeftIcon>
        <StyledInputLabel>{label}</StyledInputLabel>
        <StyledTextInput {...props} />
        {isPassword && (
          <RightIcon
            onPress={() => {
              setHidePassword(!hidePassword);
            }}
          >
            <Ionicons name={hidePassword ? 'md-eye-off' : 'md-eye'} size={30} color={darkLight} />
          </RightIcon>
        )}
      </View>
    );
  };

export default Login