import React, { useState, useContext, useRef, useEffect } from 'react';

import { StatusBar } from 'expo-status-bar';
import { FlatGrid } from 'react-native-super-grid';
import {TextInput} from 'react-native-gesture-handler';
import { View, TouchableOpacity, ActivityIndicator, Button, Text, StyleSheet, Alert } from 'react-native';
import { useFocusEffect, useScrollToTop } from '@react-navigation/native';
import { Formik } from 'formik';
import { Octicons, Ionicons, FontAwesome5 } from '@expo/vector-icons';
import KeyboardAvoidingWrapper from '../components/KeyboardAvoidingWrapper';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CredentialsContext } from '../components/CredentialsContext';

import {
  StyledContainer,
  PageTitle,
  StyledInputLabel,
  StyledFormArea,
  StyledButton,
  FlatGridHeader,
  StyledTextInput,
  StyledText,
  LeftIcon,
  RightIcon,
  InnerContainer,
  WelcomeContainer,
  ButtonText,
  MsgBox,
  Line,
  ExtraView,
  ExtraText,
  TextLink,
  TextLinkContent,
  SubTitle,
  Colors,
  styles
} from '../components/styles';

//colors
const { darkLight, brand, primary } = Colors;


const ChangeStorage = ({ navigation }) => {
  
  const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
  const { name, vorname, photoUrl, access_token,company} = storedCredentials; 
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState();
  const [dataTool, setDataTool] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
 

 
  
  useFocusEffect(
    React.useCallback(() => {
      code();
      getUsers();
     // alert('Screen was focused');
      // Do something when the screen is focused
      return () => {
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );

  useEffect(() => {
    getUsers();
  }, [currentPage])


  useEffect(() => {
    setItems([]);     
    setCurrentPage(1);
    getUsers();
    
  }, [searchText])


function newPlatceSet(vorname, name) {
  setIsLoading(true);
    const inhal = {id: dataTool.id , name: dataTool.name , lagerort: vorname.vorname +" "+name.name ,ursprungsort: dataTool.ursprungsort, image: dataTool.image , file1 : dataTool.file1, file2: "emty", file3: "emty"};
    //console.log("inhal" , inhal);
  const url = 'https://www.ztool.ch/werkzeug/werkzeug/update.php?company='+company+''
  //console.log(vorname);
  axios.post(url, inhal, {
    headers: {
      'Authorization': `Bearer ${access_token}`,'Cache-Control' : 'no-cache'
    }
  })

  .then( function(res) {
    
        setIsLoading(false);
        navigation.navigate('Werkzeuge')    
  })
  }

  function newPlatceSetBack(vorname, name, pressed) {
    setIsLoading(true);
    const inhal = {id: dataTool.id , name: dataTool.name , lagerort: dataTool.ursprungsort ,ursprungsort: dataTool.ursprungsort, image: dataTool.image , file1 : dataTool.file1, file2: "emty", file3: "emty"};
  const url = 'https://www.ztool.ch/werkzeug/werkzeug/update.php?company='+company+''
 
  
  axios.post(url, inhal, {
    headers: {
      'Authorization': `Bearer ${access_token}`,'Cache-Control' : 'no-cache'
    }
  })


  .then( function(res) {
    
    setIsLoading(false);
        
        navigation.navigate('Werkzeuge')    
  })
}

function newPlatceFlat(vorname, name) {
  setIsLoading(true);
  const inhal = {id: dataTool.id , name: dataTool.name , lagerort: vorname +" "+name ,ursprungsort: dataTool.ursprungsort, image: dataTool.image , file1 : dataTool.file1, file2: "emty", file3: "emty"};
const url = 'https://www.ztool.ch/werkzeug/werkzeug/update.php?company='+company+''
//console.log(vorname);

axios.post(url, inhal, {
  headers: {
    'Authorization': `Bearer ${access_token}`,'Cache-Control' : 'no-cache'
  }
})

.then( function(res) {
  
      setIsLoading(false);
      navigation.navigate('Werkzeuge')  
})
}
  function getUsers() {
    var axios = require('axios');
    var data = JSON.stringify([
      {
        "columnName": "name",
        "columnLogic": "LIKE",
        "columnValue": searchText
      },
      {
        "columnName": "vorname",
        "columnLogic": "LIKE",
        "columnValue": searchText
      }
      
    ]);
    
   const pagesize = 10;
    
    var config = {
      method: 'post',
      url: 'https://ztool.ch/werkzeug/benutzer/search_by_column.php?orAnd=OR&pageno='+currentPage+'&pagesize='+pagesize+'&company='+company+'',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${access_token}`,
        'Cache-Control' : 'no-cache'
      },
      data : data
    };
    setIsLoading(true);
    axios(config)
    .then(function (res) {    
      setTotalPages(Math.ceil(res.data.document.total_count/pagesize));
      //console.log("Totalpages",totalPages, "currentPage", currentPage);
      //console.log("company",res.data.company);
      //const filtered = res.data.document.records.filter(item => item.ID !== id)
      const filtered = res.data.document.records;
      if(currentPage == 1){
      setItems(filtered)
    }
    else{
      
        setItems([...items,...filtered])
         
    }
    setIsLoading(false);
    })
    .catch(function (error) {
     
      console.log(error);
    });
    
          
      }
      
    
  

const code = async() => {
  //console.log('proceessed');
  await AsyncStorage.getItem('QrCode')
      .then((result) => {
        
        
      if (result !== null) {
        const url = 'https://www.ztool.ch/werkzeug/werkzeug/read_one.php?id='+result+'&company='+company+'';
//console.log(result);

        axios.get(url , {
  headers: {
    'Authorization': `Bearer ${access_token}`,'Cache-Control' : 'no-cache'
  }
})
.then( function(res) {
  setDataTool(res.data.document);
  //setItems(res.data.document.records);
  //console.log(dataTool.id);  
})        
      } else {
        setDataTool(null);
      }
    })
    .catch((error) => console.log(error));
};
  

  const handleMessage = (message, type = '') => {
    setMessage(message);
    setMessageType(type);
  };

  const loadMoreItem =() => {if(currentPage< totalPages) {
    setCurrentPage(currentPage+1);
    //console.log("loadMore");
  }
  }  
const goToDetails = () => {/*console.log("details")*/;navigation.navigate("Werkzeugdetails")}

  const HeaderInhalt =() =>{
    return(
          <Formik
            initialValues={{ id: dataTool.id, name: dataTool.name, lagerort: dataTool.lagerort, ursprungsort: dataTool.ursprungsort }}
            onSubmit={(values, { setSubmitting }) => {
              
              values = { ...values};
              if (
               // values.id == '' ||
                values.name == '' ||
                values.lagerort == '' ||
                values.ursprungsort == ''
              ) {
                handleMessage('Please fill in all fields');
                setSubmitting(false);
              }  else {
                handleSignup(values, setSubmitting);
              }
            }}
          >
            {({ handleChange, handleBlur, handleSubmit, values, isSubmitting, isNewPlaceSet }) => (
              
              <StyledFormArea>
                <StyledButton onPress={goToDetails}>
                <ButtonText>Details</ButtonText>
              </StyledButton>

                <MyTextInput
                  label="ID"
                  placeholder={dataTool.id}
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('id')}
                  onBlur={handleBlur('id')}
                  value={values.id}
                  icon="tools"
                />

                <MyTextInput
                  label="Bezeichnung"
                  placeholder= {dataTool.name}
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('name')}
                  onBlur={handleBlur('name')}
                  value={values.name}
                  icon="tools"
                />
                <MyTextInput
                  label="Lagerort"
                  placeholder={dataTool.lagerort}
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('lagerort')}
                  onBlur={handleBlur('lagerort')}
                  value={values.lagerort}
                  icon="chevron-right"
                />
                <MyTextInput
                  label="Ursprungsort"
                  placeholder={dataTool.ursprungsort}
                  placeholderTextColor={darkLight}
                  onChangeText={handleChange('ursprungsort')}
                  onBlur={handleBlur('ursprungsort')}
                  value={values.ursprungsort}
                  icon="home"
                  
                />
                
                
                <MsgBox type={messageType}>{message}</MsgBox>


                {dataTool.lagerort != dataTool.ursprungsort && (
          <StyledButton onPress={() => newPlatceSetBack()}>
              <ButtonText>Zurück in {dataTool.ursprungsort} speichern</ButtonText>
            </StyledButton>

          )}

          {dataTool.lagerort != (vorname+' '+name) && (
            <StyledButton onPress={() => newPlatceSet({vorname},{name})}>
              <ButtonText>bei {vorname} {name} speichern</ButtonText>
            </StyledButton>
            
          )}     
                <Line />
               
                
              </StyledFormArea>
            )}
          </Formik>
          
        )
  }
  return (
    
    <FlatGrid
    
    ListHeaderComponent={
    
      <FlatGridHeader >
        <StatusBar style="dark" />
        <HeaderInhalt/>
          
          
            <ExtraText>Oder Speichern bei: </ExtraText>
            <TextInput style={styles.input} placeholder='Benutzer durchsuchen'  onChangeText={(text)=> {setSearchText(text)}}></TextInput>
          
            </FlatGridHeader>
      
       
    }
    keyExtractor={(item) => item.ID}
    itemDimension={130}
    data={items}
    style={styles.gridView}
    onEndReachedThreshold={0.5}
    //onEndReached={////console.log("End")}
    onEndReached={({ distanceFromEnd }) => {
      loadMoreItem();
    }}
    // staticDimension={300}
    // fixed
    spacing={10}
    renderItem={({ item }) => (
      <TouchableOpacity onPress={() => newPlatceFlat(item.vorname,item.name)}>
      <View style={[styles.itemContainer, { backgroundColor: '#1abc9c' }]}>
       <Text style={styles.itemName}>{item.name}</Text>
        <Text style={styles.itemCode}>{item.vorname}</Text>
      </View>
      </TouchableOpacity>
    )}
  />
  );
};

const MyTextInput = ({ label, icon, value, isPassword, hidePassword, setHidePassword, isDate, showDatePicker, ...props }) => {
  return (
    <View>
      <LeftIcon>
        <Octicons name={icon} size={30} color={brand} />
      </LeftIcon>
      <StyledInputLabel>{label}</StyledInputLabel>
      

       <StyledText {...props}>{value}</StyledText>

      {isPassword && (
        <RightIcon
          onPress={() => {
            setHidePassword(!hidePassword);
          }}
        >
          <Ionicons name={hidePassword ? 'md-eye-off' : 'md-eye'} size={30} color={darkLight} />
        </RightIcon>
      )}
    </View>
  );
};

export default ChangeStorage;
