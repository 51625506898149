import React, { useState, useContext, useRef, useEffect } from 'react';
import { webUrl } from '../components/connection';
import { StatusBar } from 'expo-status-bar';
import { FlatGrid } from 'react-native-super-grid';
import { Octicons, Ionicons, FontAwesome5 } from '@expo/vector-icons';
import KeyboardAvoidingWrapper from '../components/KeyboardAvoidingWrapper';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CredentialsContext } from '../components/CredentialsContext';
import { Formik } from 'formik';
import { View, TouchableOpacity, ActivityIndicator, Button, Text, StyleSheet, Image, TextInput, Alert, Platform } from 'react-native';
import { useFocusEffect, useScrollToTop } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser'
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import QRCode from 'react-native-qrcode-svg';
import * as Print from 'expo-print';
import { useIsFocused } from '@react-navigation/core';
import {
  StyledContainer,
  PageTitle,
  StyledInputLabel,
  StyledFormArea,
  StyledButton,
  StyledTextInput,
  LeftIcon,
  RightIcon,
  InnerContainer,
  ButtonText,
  MsgBox,
  Line,
  ExtraView,
  ExtraText,
  TextLink,
  TextLinkContent,
  SubTitle,
  Colors,
  styles
} from '../components/styles';

const { darkLight, brand, primary, black } = Colors;

const EditUsers = ({ navigation }) => {
  
  const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
  const { name, vorname, photoUrl, access_token} = storedCredentials;
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [QRref, setQRref] = useState();
  const [base64, setBase64] =useState();
  const [serverResponseFile, setServerResponseFile] = useState("emty");
  const [serverResponseImage, setServerResponseImage] = useState("emty");
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState();
  const [dataTool, setDataTool] = useState([]);
  const [items, setItems] = useState([]);
  const ref = useRef(null);
  const didMountFile = useRef(false);
  const didMountImage = useRef(false);
  const isFocused = useIsFocused();
  useScrollToTop(ref);

  useEffect(() => {
    if(isFocused == true){
      
      //console.log("focused")
    } else{
      //console.log("unfocused")
      
      //alert("lost Focus welcome")
    }
  }
  , [isFocused])

   
  useFocusEffect(
    React.useCallback(() => {
      code();
      //getNewsFromAPI();
     // alert('Screen was focused');
      // Do something when the screen is focused
      return () => {
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );

  useEffect(() =>{
    if(didMountFile.current){uploadFile();
    } else {
      didMountFile.current = true

    } },[file])


    useEffect(() =>{
      if(didMountImage.current){uploadImage();
      } else {
        didMountImage.current = true
  
      } },[image])

  useEffect(() => {
    
    (async () => {
      
        const { status } = await ImagePicker.requestCameraPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      
    })();
  }, []);



  useEffect(() =>{
    if(Platform.OS != 'web'){
    if(QRref){
    QRref.toDataURL((data)=>{setBase64(data)})
  } else{console.log('no qr')}}
    },[QRref])

    const html = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Nr: ${dataTool.id}
    </h1>
    <img
      src="data:image/png;base64,${base64}"
      style="width: 90vw;" />
      <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Ort: ${dataTool.ursprungsort}
    </h1>
  </body>
</html>
`;

    const print = async () => {
      
     
      navigation.navigate("Webprint");
       }
    

    

const updateTool = (value, name) =>{
  console.log("server respomse",serverResponseFile);
  if(serverResponseFile == "emty" || serverResponseFile == "undefined" || serverResponseFile == "") {console.log("no File") }else{ dataTool.file1= serverResponseFile.document};
  if(serverResponseImage == "emty" || serverResponseImage == "undefined" || serverResponseImage == "") {console.log("no image") }else{ dataTool.image= serverResponseImage.document};
  if(value.name) {dataTool.name = value.name};
  if(value.lagerort) {dataTool.lagerort = value.lagerort};
  if(value.ursprungsort) {dataTool.ursprungsort = value.ursprungsort};  
 // const inhal = {id: dataTool.id , name: dataTool.name , lagerort: "DDD" ,ursprungsort: dataTool.ursprungsort};
    const inhal = {id: dataTool.id , name: dataTool.name , lagerort: dataTool.lagerort ,ursprungsort: dataTool.ursprungsort , image: dataTool.image , file1 : dataTool.file1, file2: "emty", file3: "emty"};
  //console.log("datatol",inhal);
    const url = 'https://www.ztool.ch/werkzeug/werkzeug/update.php'
  //console.log(vorname);
  axios.post(url, inhal, {
    headers: {
      'Authorization': `Bearer ${access_token}`,'Cache-Control' : 'no-cache'
    }
  })

  .then( function(res) {
        //console.log(res);
        //navigation.navigate('Home')
        alert("Gespeichert")
   })
  }

  

const code = async() => {
  //console.log('proceessed');
  await AsyncStorage.getItem('BenutzerID')
      .then((result) => {
        //console.log("übergabe",result);
        
      if (result !== null) {
        const url = 'https://www.ztool.ch/werkzeug/benutzer/read_one.php?id='+result+'';
//console.log(result);

        axios.get(url , {
  headers: {
    'Authorization': `Bearer ${access_token}`,'Cache-Control' : 'no-cache'
  }
})
.then( function(res) {
  const result = res.data.document;
  console.log("read one",result);
  //result.data = 'data:'+result.mimeType+';base64,'+result.data;
  setDataTool(result);
  //setItems(res.data.document.records);
  
  
})        
      } else {
        setDataTool(null);
      }
    })
    .catch((error) => console.log(error));
};
  

  const handleMessage = (message, type = '') => {
    setMessage(message);
    setMessageType(type);
  };

 

 const openFile = async () =>{
  await WebBrowser.openBrowserAsync(webUrl+'files/upload/files/'+dataTool.file1);
  
}

const openFileNew = async () =>{
  await WebBrowser.openBrowserAsync(webUrl+'files/upload/files/'+serverResponseFile.document);
  
}

 const editFile = () => {
  Alert.alert('Datei bearbeiten', '', [
    { text: 'Datei ändern', onPress: () => pickFile(), style: 'default' },
    
    {
      text: 'Cancel',
      onPress: () => console.log('Cancel Pressed'),
      style: 'cancel',
    },
    
  ]);
   
 }

 const pickFile = async () => {
  let result = await DocumentPicker.getDocumentAsync({
    type: '*/*',
    base64: true
  }); 
  if (!result.cancelled) {
    //console.log("OK")
    if(Platform.OS !== 'web'){
      const base64 = await FileSystem.readAsStringAsync(result.uri,{ encoding: FileSystem.EncodingType.Base64 })
      //console.log(result);     
      setFile({
      
      "nameBilder": result.name,
      "mimeType": result.mimeType,
      "size": result.size,
      "data": base64,
    });
      
  } else {
    //console.log('Platform Web', result)
    const base64 = result.uri.split(',').pop();
    //console.log('base64', base64);

   setFile({
      
      "nameBilder": result.name,
      "mimeType": result.mimeType,
      "size": result.size,
      "data": base64,
    });
    
  }
}
};

const uploadFile = async () => {
      
      
  var axios = require('axios');
  var data = JSON.stringify(file);
  //console.log(data);
  var config = {
    method: 'post',
    url: webUrl+'files/uploadfile.php',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${access_token}`
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    //console.log(JSON.stringify(response.data.document));
    setServerResponseFile(response.data);
  })
  .catch(function (error) {
    //console.log(error);
  });
  
}




const uploadImage = async () => {
    
    
  var axios = require('axios');
  var data = JSON.stringify(image);
  //console.log(data);
  var config = {
    method: 'post',
    url: webUrl+'files/uploadimage.php',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${access_token}`
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    //console.log("serverresponse Image",JSON.stringify(response.data.document));
    setServerResponseImage(response.data);
  })
  .catch(function (error) {
    //console.log(error);
  });
  
}

const deleteUser = () => {

  var axios = require('axios');
var data = JSON.stringify({
  "ID": dataTool.ID
});

var config = {
  method: 'post',
  url: webUrl+'benutzer/delete.php',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${access_token}`
  },
  data : data
};

axios(config)
.then(function (response) {
  //console.log(JSON.stringify(response.data))
  //console.log(response.data.status);
  if(response.data.status == 'success'){
    if(Platform.OS == 'web'){
    alert('Werkzeug wurde gelöscht');}
    else
    {Alert.alert('Werkzeug wurde gelöscht')}
    navigation.navigate("Benutzer")
      
  }
})
.catch(function (error) {
  console.log(error);
});

  


}



  return (
    
    <KeyboardAvoidingWrapper>
    <StyledContainer>
      <StatusBar style="dark" />
      <InnerContainer> 
     
     
          
          
        <Formik
          initialValues={{ id: dataTool.id, name: dataTool.name, lagerort: dataTool.lagerort, ursprungsort: dataTool.ursprungsort }}
          onSubmit={(values, { setSubmitting }) => {
            
            values = { ...values};
            if (
             // values.id == '' ||
              values.name == '' ||
              values.lagerort == '' ||
              values.ursprungsort == ''
            ) {
              handleMessage('Please fill in all fields');
              setSubmitting(false);
            }  else {
              updateTool(values, setSubmitting);
            }
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
            <StyledFormArea>

              <MyTextInput
                label="ID"
                placeholder={dataTool.ID}
                placeholderTextColor={black}
                onChangeText={handleChange('ID')}
                onBlur={handleBlur('ID')}
                value={values.ID}
                icon="chevron-right"
                editable={false}
              />

              <MyTextInput
                label="Name"
                placeholder={dataTool.name}
                placeholderTextColor={black}
                onChangeText={handleChange('name')}
                onBlur={handleBlur('name')}
                value={values.name}
                icon="chevron-right"
                editable={false}
              />

              <MyTextInput
                label="Vorname"
                placeholder= {dataTool.vorname}
                placeholderTextColor={black}
                onChangeText={handleChange('name')}
               onBlur={handleBlur('name')}
                value={values.name}
                icon="chevron-right"
                editable={false}
              />
              <MyTextInput
                label="Benutzer typ"
                placeholder={dataTool.account_typ}
                placeholderTextColor={black}
                onChangeText={handleChange('account_typ')}
                onBlur={handleBlur('account_typ')}
                value={values.account_typ}
                icon="chevron-right"
                editable={false}
              />
              
              
              
              <MsgBox type={messageType}>{message}</MsgBox>


              
              
             <StyledButton onPress={deleteUser}>
               <ButtonText>Benutzer löschen</ButtonText>
              </StyledButton>

               



            </StyledFormArea>
          )}
        </Formik>
        
      </InnerContainer>
      
    </StyledContainer>
  </KeyboardAvoidingWrapper>
  );
};

const MyTextInput = ({ label, icon, isPassword, hidePassword, setHidePassword, isDate, showDatePicker, ...props }) => {
  return (
    <View>
      <LeftIcon>
        <Octicons name={icon} size={30} color={brand} />
      </LeftIcon>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledTextInput {...props} />

      
    </View>
  );
};

export default EditUsers;
