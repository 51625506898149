
import React, { useState, useEffect, useCallback } from 'react';
import * as Font from 'expo-font'
import Entypo from '@expo/vector-icons/Entypo';
import { webUrl } from './components/connection';

import axios from 'axios';

// React navigation stack
import RootStack from './navigators/RootStack';

// apploading
import AppLoading from 'expo-app-loading';
import * as SplashScreen from 'expo-splash-screen'

// async-storage
import AsyncStorage from '@react-native-async-storage/async-storage';

// credentials context
import { CredentialsContext } from './components/CredentialsContext';

export default function App() {
  const [appReady, setAppReady] = useState(false);
  const [storedCredentials, setStoredCredentials] = useState("");
  

  
  const checkLoginCredentials = () => {
    AsyncStorage.getItem('flowerCribCredentials')
      .then((result) => {
        if (result !== null) {
          const credentials =(JSON.parse(result))
          const url = 'https://www.ztool.ch/werkzeug/token/validatetoken.php'
         //console.log('data' , credentials)
          

          axios.get(url, {
            headers: {
              'Authorization': `Bearer ${credentials.access_token}`,'Cache-Control' : 'no-cache'
            }
          })
          .then(function(res){
            if( res.status == 200){
              setStoredCredentials(credentials);
              setAppReady(true);
             
            }
            else  {
              setStoredCredentials(null);
              
              setAppReady(true);
            }
          })   
                   
        } else {
          setStoredCredentials(null);
          
          setAppReady(true);
        }
        
      })
      
     // .catch((error) => console.log(error));
      
  };
 
 /* useEffect(() => {
    
      checkLoginCredentials();
      //console.log("cheking credentials")
     
  }, []);*/


  useEffect(() => {
    async function prepare() {
      try {
        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync(Entypo.font);
        checkLoginCredentials();
        // Artificially delay for two seconds to simulate a slow loading
        // experience. Please remove this if you copy and paste the code!
        //await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppReady(true);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appReady]);

  if (!appReady) {
    return null;
  }

 /* if (!appReady) {
    return <SplashScreen startAsync={checkLoginCredentials} onFinish={() => setAppReady(true)} onError={console.warn} />;
  }*/

  return (
    <CredentialsContext.Provider value={{ storedCredentials, setStoredCredentials }}onLayout={onLayoutRootView}>
      
      <RootStack />
    </CredentialsContext.Provider>
  );
}
