
import { webUrl } from '../components/connection';
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Text, TouchableOpacity, Button, Platform, Image, Keyboard,ActivityIndicator } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { Camera } from 'expo-camera';
import { useIsFocused } from '@react-navigation/core';
import { useFocusEffect} from '@react-navigation/native';
import { BarCodeScanner } from 'expo-barcode-scanner';






import {
  Avatar,
  WelcomeImage,
  PageTitle,
  SubTitle,
  StyledFormArea,
  StyledButton,
  InnerContainer,
  WelcomeContainer,
  ButtonText,
  Line,
  styles,
  Colors
  
} from './../components/styles';
const { darkLight, brand, primary } = Colors;

// Async storage
import AsyncStorage from '@react-native-async-storage/async-storage';

// credentials context
import { CredentialsContext } from './../components/CredentialsContext';
import { TextInput } from 'react-native-gesture-handler';

const Welcome = ({navigation}) => {
  // credentials context
  const { storedCredentials, setStoredCredentials } = useContext(CredentialsContext);

  const { name, vorname, photoUrl, access_token, company} = storedCredentials;
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(100000);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState();
  const isFocused = useIsFocused();
  const firstLoad = useRef(true)
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [scroll, setScroll] = useState(false);
  //console.log(access_token)
/*useFocusEffect(
  React.useCallback(() => {
     getWerkzeug();
   // alert('Screen was focused');
    // Do something when the screen is focused
    return () => {
      
      //alert('Screen was unfocused');
      // Do something when the screen is unfocused
      // Useful for cleanup functions
    };
  }, [])
);*/

useEffect(() => {
  (async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === 'granted');
  })();
}, []);



useEffect(() => {
  if(isFocused == true){
    
    getWerkzeug();
    //console.log("focused");
  } else{
    setItems([]);
    setCurrentPage(1);
    //console.log("focused");
    //alert("lost Focus welcome")
  }
}
, [isFocused])

useEffect(() => {
 
  if(isFocused == true){ 
     getWerkzeug();
  }
}, [currentPage])

useEffect(() => {
  setItems([]);     
  setCurrentPage(1);
  getWerkzeug();
  
}, [searchText])

function openScanned(type, data) {
  try { AsyncStorage.setItem("QrCode" , data);
  //console.log('saved', data);
  navigation.navigate('Lagerort speichern');
} catch (err) {
  alert(err);
}

  
  
  //console.log(type + data)
}





const handleBarCodeScanned = ({ type, data }) => {
  setScanned(true);
  openScanned(type, data);   
  //alert(`Bar code with type ${type} and data ${data} has been scanned!`);
};

/*if (hasPermission === null) {
  return <Text>Requesting for camera permission</Text>;
}
/*if (hasPermission === false) {
  return <Text>No access to camera</Text>;
}*/



function getWerkzeug(text) {
  //console.log("company",company);
  var axios = require('axios');
  var data = JSON.stringify([
    {
      "columnName": "id",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
    {
      "columnName": "name",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
    {
      "columnName": "lagerort",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    },
    {
      "columnName": "ursprungsort",
      "columnLogic": "LIKE",
      "columnValue": searchText
      
    }]);
  
  const pagesize = 30;
    
    var config = {
      method: 'post',
      url: webUrl+'werkzeug/search_by_column.php?orAnd=OR&pageno='+currentPage+'&pagesize='+pagesize+'&company='+company+'',
      //url: webUrl+'werkzeug/search_by_column.php?orAnd=OR&pageno='+currentPage+'&pagesize='+pagesize+'',
     //url: webUrl+'werkzeug/search_by_column.php?orAnd=OR&pageno=1&pagesize=5',
      headers: { 
        
        'Authorization': `Bearer ${access_token}`,
        'Cache-Control' : 'no-cache'
      },
      data : data
    };
    
    axios(config)
    
    .then(function (res) {    
     setTotalPages(Math.ceil(res.data.document.total_count/pagesize));
     //console.log("Totalpages",totalPages, "currentPage", currentPage,"totoal_count", res.data.document.total_count);
     //console.log(res.data.company);
     //console.log(res.data);
     
     
      
    // setItems(res.data.document.records);
    
       const filtered = res.data.document.records;
       //console.log(filtered)
       filtered.forEach((item) => item.image = 'https://www.ztool.ch/werkzeug/files/upload/images/'+item.image)

      if(currentPage == 1){
      setItems(filtered)
      //console.log(items);
         
    }
    else{
      setItems([...items,...filtered])
     // console.log(items);
         
    }
    
    })
    .catch(function (error) {
     
      //console.log(error);
    });
    
          
      }

    const loadMoreItem =() => {
     // console.log("load more totalPages",totalPages ,"current page", currentPage);
     //console.log("Load more , total pages", totalPages,"current page", currentPage);
      
      if(currentPage< totalPages) {
      setCurrentPage(currentPage+1);
      
     // console.log("loadMore");
    }
    } 

const HeaderInhaltt = () =>{
 if(Platform.OS !=='web'){
   
  return(
    <InnerContainer>
         
     
{isFocused && (
      <Camera  
     
      barCodeScannerSettings={{barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],}}
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={styles.barcodebox}
      />)}
      {scanned && <Button title={'Drücken für scan'} onPress={() => setScanned(false)} />}  
          <StyledFormArea>
         <Line />
      </StyledFormArea>
      
      </InnerContainer>
  )}else {
    return(
    <StyledFormArea>
    <Line />
    </StyledFormArea>)}
}

const CameraView = ()=>{
  if(Platform.OS =='web') {
   
  if(hasPermission === false){  
    return(
     <View>
    <Text>No access to camera {hasPermission}</Text>
    </View>
 )
  }else if ( isFocused === true && hasPermission === true ){
    return(
      <View style={{height:300, alignItems:'center', transform:[{scaleX:-1}]}}>
      <Camera  
      barCodeScannerSettings={{barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],}}
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={styles.barcodeboxWeb}
      />
       
      </View>
    )
  }
  else{
    return(
    <View>     
      
      
    </View>
    )
  }
}else{
  return(
    <StyledFormArea>       
    <Line />
    </StyledFormArea>   
  )
}
}


/*const CameraView = ()=>{
  if(Platform.OS =='web') {
    //console.log("haspermission",hasPermission,"isfocused",isFocused)
    
    return(
      <InnerContainer>
       <WelcomeContainer>
         <View>
      
{hasPermission === false &&( <Text>No access to camera</Text>)}
</View>
{isFocused && hasPermission === true && (
      <Camera  
      barCodeScannerSettings={{barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],}}
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={styles.barcodebox}
      />)}
      {scanned && <Button title={'Tap to Scan Again'} onPress={() => setScanned(false)} />}  
        
            
                 
        </WelcomeContainer>
      </InnerContainer>
    )
  }else{
    return(
      <StyledFormArea>       
      <Line />
      </StyledFormArea>   
    )
  }
}*/
const Footer = () =>  {
  
  if(Platform.OS == "web"){ 
   return( <View>{currentPage < totalPages &&(<StyledButton onPress={loadMoreItem}>
    <ButtonText>Load more</ButtonText>
  </StyledButton>)}
  </View>)}
  else{
    return(<View/>)
  }
} ;

//Damit kamera wieder aktiv ist nach scroll
const onScroll = useCallback(({ nativeEvent }) => {
  //console.log('scroll');
  if (nativeEvent.contentOffset.y > 100) setScroll(false);
  if (nativeEvent.contentOffset.y <= 100) setScroll(true);
}, []);

  return (
    
    
      <><CameraView /><FlatGrid
      onScroll={onScroll}
      ListFooterComponent={<Footer/>}
      ListHeaderComponent={<View style={{flex:1}}>
        <StatusBar style="light" />
        <HeaderInhaltt />
        <TextInput placeholder={"Nach werkzeug suchen"} style={styles.input} onChangeText={(text) => { setSearchText(text); } } />
      </View>}
      keyExtractor={(item) => item.id}
      itemDimension={130}
      data={items}
      style={styles.gridView}
      onEndReachedThreshold={0.5}
      //onEndReached={console.log("End")}
      onEndReached={() => {
        loadMoreItem();
      } }
      // staticDimension={300}
      // fixed
      spacing={10}
      renderItem={({ item }) => (
        <TouchableOpacity onPress={() => openScanned({}, item.id)}>
          <View style={[styles.itemContainer, { backgroundColor: '#1abc9c' }]}>
            <Text style={styles.itemName}>{item.id}</Text>
            <Text style={styles.itemName}>{item.name}</Text>
            <Text style={styles.itemCode}>{item.lagerort}</Text>
            <Image style={{ width: 50, height: 50, }} source={{ uri: item.image }} />
          </View>
        </TouchableOpacity>
      )} 
      /> 
      
      </>
      
    
  );
};

export default Welcome;

