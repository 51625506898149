import React, { useState, useContext, useRef, useEffect } from 'react';
import { webUrl } from '../components/connection';
import { StatusBar } from 'expo-status-bar';
import { FlatGrid } from 'react-native-super-grid';
import { Octicons, Ionicons, FontAwesome5 } from '@expo/vector-icons';
import KeyboardAvoidingWrapper from '../components/KeyboardAvoidingWrapper';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CredentialsContext } from '../components/CredentialsContext';
import { Formik } from 'formik';
import { View, TouchableOpacity, ActivityIndicator, Button, Text, StyleSheet, Image, TextInput, Alert, Platform } from 'react-native';
import { useFocusEffect, useScrollToTop } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser'
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import QRCode from 'react-native-qrcode-svg';
import * as Print from 'expo-print';
import { useIsFocused } from '@react-navigation/core';
import {
  StyledContainer,
  PageTitle,
  StyledInputLabel,
  StyledFormArea,
  StyledButton,
  StyledTextInput,
  LeftIcon, 
  RightIcon,
  InnerContainer,
  ButtonText,
  MsgBox,
  Line,
  ExtraView,
  ExtraText,
  TextLink,
  TextLinkContent,
  SubTitle,
  Colors,
  styles
} from '../components/styles';

const { darkLight, brand, primary, black } = Colors;

const EditTools = ({ navigation }) => {
  
  const {storedCredentials, setStoredCredentials} = useContext(CredentialsContext);
  const { name, vorname, photoUrl, access_token,company} = storedCredentials;
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [QRref, setQRref] = useState();
  const [base64, setBase64] =useState();
  const [serverResponseFile, setServerResponseFile] = useState("emty");
  const [serverResponseImage, setServerResponseImage] = useState("emty");
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState();
  const [dataTool, setDataTool] = useState([]);
  const [items, setItems] = useState([]);
  const ref = useRef(null);
  const didMountFile = useRef(false);
  const didMountImage = useRef(false);
  const isFocused = useIsFocused();
  useScrollToTop(ref);

  useFocusEffect(
    React.useCallback(() => {
      code();
      
     // alert('Screen was focused');
      // Do something when the screen is focused
      return () => {
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );
  

    const code = async() => {
      //console.log('proceessed');
      await AsyncStorage.getItem('QrCode')
          .then((result) => {
            
            
          if (result !== null) {
            const url = 'https://www.ztool.ch/werkzeug/werkzeug/read_one.php?id='+result+'&company='+company+'';
    //console.log(result);
    
            axios.get(url , {
      headers: {
        'Authorization': `Bearer ${access_token}`,'Cache-Control' : 'no-cache'
      }
    })
    .then( function(res) {
      setDataTool(res.data.document);
      //setItems(res.data.document.records);
      //console.log(dataTool.id);
      print();  
    })        
          } else {
            setDataTool(null);
          }
        })
        .catch((error) => console.log(error));
    };
 
  

    const print = async () => {
      // On iOS/android prints the given html. On web prints the HTML from the current page.
      await Print.printToFileAsync({ width:5000, height:10
        
      });
      navigation.navigate('Werkzeugdetails')  
     
    }


  return (
    
    
    <View style={{alignItems:'center',width:100, height:190}}>
      <StatusBar style="dark" />
      
      <Text style={{fontSize:20}} >Nr: {dataTool.id}</Text>
        
      
        <QRCode 
      value={dataTool.id}
      size={80}
           
    />
    
    <Text style={{fontSize:20}}>{dataTool.ursprungsort}</Text>
    </View>
       
      
  
  );
};



export default EditTools;
